
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,500;0,600;0,700;1,400;1,600;1,700&.css');
@import './variables.less';

body {
  height: 100%;
  overflow-y: hidden;
  background-color: #ffffff;
  font-family: 'Mulish', sans-serif;
}

::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.margin-right {
  margin-right: 16px;
}

:global {
  .ant-breadcrumb {
    & > span {
      &:last-child {
        color: #999;
        font-weight: normal;
      }
    }
  }

  .ant-breadcrumb-link {
    .anticon + span {
      margin-left: 4px;
    }
  }

  // .ant-table {
  //   .ant-table-thead > tr > th {
  //     text-align: center;
  //   }

  //   .ant-table-tbody > tr > td {
  //     text-align: center;
  //   }

  //   &.ant-table-small {
  //     .ant-table-thead > tr > th {
  //       background: #f7f7f7;
  //     }

  //     .ant-table-body > table {
  //       padding: 0;
  //     }
  //   }
  // }

  .ant-table-pagination {
    // float: none !important;
    // display: table;
    // margin: 16px auto !important;
    .ant-pagination-total-text {
      margin-right: auto;
    }
    .ant-pagination-options {
      margin-left: auto !important;
    }
  }

  .ant-popover-inner {
    border: none;
    border-radius: 0;
    box-shadow: 0 0 20px rgba(100, 100, 100, 0.2);
  }

  .ant-form-item-control {
    vertical-align: middle;
  }

  .ant-modal-mask {
    background-color: rgba(55, 55, 55, 0.2);
  }

  .ant-modal-content {
    box-shadow: none;
  }

  .ant-select-dropdown-menu-item {
    padding: 12px 16px !important;
  }

  a:focus {
    text-decoration: none;
  }

  .ant-table-layout-fixed table {
    table-layout: auto;
  }
}
@media (min-width: 1600px) {
  :global {
    .ant-col-xl-48 {
      width: 20%;
    }

    .ant-col-xl-96 {
      width: 40%;
    }
  }
}
@media (max-width: 767px) {
  :global {
    .ant-pagination-item,
    .ant-pagination-next,
    .ant-pagination-options,
    .ant-pagination-prev {
      margin-bottom: 8px;
    }

    .ant-card {
      .ant-card-head {
        padding: 0 12px;
      }

      .ant-card-body {
        padding: 12px;
      }
    }
  }
}
