@import './default';

@secondary-color: #00B6C1;
@dark-half: #494949;
@primary-dark: #003D4C;
@purple: #d897eb;
@shadow-1: 4px 4px 20px 0 rgba(0, 0, 0, 0.01);
@shadow-2: 4px 4px 40px 0 rgba(0, 0, 0, 0.05);
@transition-ease-in: all 0.3s ease-out;
@transition-ease-out: all 0.3s ease-out;
@ease-in: ease-in;

.ant-timeline-item-head-orange {
  color: #faad13;
  border-color: #faad13;
}

.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-gradient {
  background-image: gradient(linear,
      37.219838% 34.532506%,
      36.425669% 93.178216%,
      from(#29cdff),
      to(#0a60ff),
      color-stop(0.37, #148eff));
  background-clip: text;
}

.ant-menu-item.ant-menu-item-selected {
  color: @white;

  a {
    color: @white;
  }
}

.background-hover {
  transition: @transition-ease-in;

  &:hover {
    background-color: @hover-color;
  }
}